//Projets.js
import React from 'react';
import Projets from '../components/Projets';

const ProjetsPage = () => {
  return (
    <div className="sections">
      <Projets />
    </div>
  );
}

export default ProjetsPage;
